import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from "./components/Navbar";
import BrandList from "./components/Brands";
import Header from "./components/Header";
import Parallax from "./components/Parallax";
import Contacts from "./components/Contacts";

function App()
{
    return (
        <>
            <Navbar/>
            <Header/>
            <BrandList/>
            <Parallax img={"/vetrina.webp"}/>
            <Contacts/>
        </>
    )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode><App/></React.StrictMode>);

