import React from "react";
import "./Header.css"

export default function Header()
{
	return (
		<div id="header">
			<div className="logo">
				<img alt="Acquamarina logo" src="/logo.webp"/>
				<p className="caption">Vendita al dettaglio di biancheria intima e per la casa.<br/>Costumi e abbigliamento mare.</p>
			</div>
		</div>
	)
}