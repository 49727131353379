import React from "react";
import "./Navbar.css"

function smoothScroll(elementId)
{
	const offset = -70;
	const elementPosition = document.getElementById(elementId).offsetTop;
	window.scrollTo({top: elementPosition + offset, behavior: "smooth"});
}

function NavbarLink({name, elementId})
{
	return (<button className="nav-link" onClick={() => smoothScroll(elementId)}>{name}</button>)
}

export default function Navbar()
{
	return (
		<nav>
			<NavbarLink elementId="root" name="Home"/>
			<NavbarLink elementId="brands" name="I nostri marchi"/>
			<NavbarLink elementId="contacts" name="Contatti"/>
		</nav>
	)
}